const linkify = (inputText: string, target: string = '_blank'): string => {
    let replacedText;

    // URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, `<a href="$1" target="${target}">$1</a>`);

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, `$1<a href="http://$2" target="${target}">$2</a>`);

    // Change email addresses to mailto:: links.
    const replacePattern3 = /(([a-zA-Z0-9\-_\\.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
};

export { linkify };
